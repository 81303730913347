.textTitle {
    color: white;
    font-size: 2.5em;
    margin: unset;
    font-family: Calibri,helvetica,sans-serif;
    border-bottom: medium solid black;
    padding: 20px;
}

.userFullName {
    font-weight: 700;
    font-size: 1.5em;
}

.authenticatorIntroHeader {
    font-weight: 700;
    font-size: 1.2em;
}

.authenticatorIntroText {
    font-weight: 500;
    font-size: 1em;
    margin-top: -15px;
}

.loaderTitle {
    font-weight: 700;
    font-size: 25px;
    margin-left: 20px;
}

.nncamsBaseButtons {
    margin: 2em;
    display: flex;
    justify-content: space-between;
    width: 50%;
}

.footerBox {
    border-top: 2px solid black;
}

.footer {
    font-size: 0.7em;
    display: flex;
    justify-content: space-between;
}