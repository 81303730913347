
.content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 450px;
    padding: 16px;    
}

.details {
    display: flex;
    align-items: center;    
}

.authenticatorTitle {
    width: 130px;
    font-weight: 700;    
    font-size: 1.4em;    
    margin-left: 20px;
}